let resumeData = {
    "imagebaseurl":"https://github.com/devthakker",
    "name": "Devin Thakker",
    "role": "Developer",
    "linkedinId":"devin-thakker",
    "skypeid": "Your skypeid",
    "roleDescription": "Entrepreneurial spirited and motivated undergraduate, with a demonstrated technical aptitude in computer science and high proficiency in the FinTech Industry, looking for an opportunity to leverage my education and experience in the FinTech industry, in an intern role or in the role of software developer role upon graduation.  ",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/devin-thakker",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/devthakker",
          "className":"fa fa-github"
        },
      ],
    "aboutme":"I am currently a Junior at the Luddy School of Informatics, Computing, and Engineering. I am pursuing a degree in Computer Science with a specialization in Artificial Intelligence.",
    "address":"Chicago",
    "website":"dthakker@iu.edu",
    "education":[
      {
        "UniversityName":"Indiana University Bloomington",
        "specialization":"Computer Science with a minor in Financial Literacy",
        "MonthOfPassing":"May",
        "YearOfPassing":"2025",
        "Achievements":"Epsilon Nu Tau Fraternity - Technology Chair, GPA 3.55/4.0"
      },

    ],
    "work":[
      {
        "CompanyName":"RDE Holdings",
        "specialization":"Web Developer (internship to part-time employment over school year)",
        "MonthOfLeaving":"Present",
        "YearOfLeaving":"",
        "Achievements":"Applying Agile methodology with the SCRUM framework to efficiently deliver technology; immersive Agile curriculum with ongoing coaching.	Contributing to the design and development effort of the RDE corporate website front end, ongoing activity of the Tech team. Participated in Restaurant.com platform user interface rebuild to improve customer and consumer engagement. Assisted in implementation of payment processing system, Stripe, resulting in increased efficiency of customer and backend business processes.	Managed infrastructure/technology for office relocation while planning and executing network and Azure Active Directory set up"
      },
      {
        "CompanyName":"Open Source Developer",
        "specialization":"Self Employed",
        "MonthOfLeaving":"Present",
        "YearOfLeaving":"",
        "Achievements":"Developed and published python libraries onto PyPi in relation to algorithmic trading. Icarus-BT - Python package library to facilitate back testing of trading strategies. Provides a framework to simulate and evaluate trading decisions based on historical data, calculate various performance metrics, and generate graphical representations of the results. Ilib - Library of technical indicators to enable building of trading strategies.  Written in Python, facilitates data manipulation through Pandas and Numpy library, charting via Matplotlib and supports like calculations for applications and active trading programs. Utilized high level application programming interfaces to develop software with TD Ameritrade, Alpaca Trading, and Open AI. Tested and published trading algorithms utilizing these packages and APIs  "
      },
      {
        "CompanyName":"IMC Pharmaceuticals",
        "specialization":"Auditor",
        "MonthOfLeaving":"September",
        "YearOfLeaving":"2022",
        "Achievements":"Worked within a team to audit pharmacies and hospitals nationwide for 340B and brand pharmaceuticals. Audited level 1 hospitals including Rush Memorial, Stanford Hospital, Johns Hopkins, and New York-Presbyterian Hospital. Setup processing system that give customers an average of 30% decrease on cost of invoices for disposals"
      },
      {
        "CompanyName":"Dicks Sporting Goods",
        "specialization":"Golf and Shoe Sales Associate",
        "MonthOfLeaving":"March",
        "YearOfLeaving":"2020",
        "Achievements":"Worked in the footwear department as a sales associate assisting customers with fittings for performance shoes. Advanced to the golf and tennis department as a sales associate helping customers select the best fit clubs and racquets. Association of Golf Club Fitting certified to complete club and racquet fittings for customers"
      }
    ],
    "skillsDescription":"Languages I am familiar with:",
    "skills":[
      {
        "skillname":"Python"
      },
      {
        "skillname":"Java"
      },
      {
        "skillname":"C"
      },
      {
        "skillname":"Android Development"
      }
    ],
    "portfolio":[
      {
        "name":"Neural Networks for Boston Housing Prices",
        "description":"Using Neural Networks, Linear Regression, and Decision Tree Regressors to Predict the Boston housing Market values. ",
        "imgurl":"images/portfolio/nn.png",
        "url":"https://github.com/devthakker/Neural-Networks-for-Housing-Markets"
      },
      {
        "name":"Red Bank",
        "description":"WClassifications For The Fraudulent Credit Card Charges",
        "imgurl":"images/portfolio/cc.webp",
        "url":"https://github.com/devthakker/Credit-Card-Fraud-Detection-B365"
      },
      // {
      //   "name":"Python technical Indicator Library",
      //   "description":"Library of technical indicators for stock analysis to calculate many different indicators such as RSI, MACD, and Bollinger Bands.",
      //   "imgurl":"images/portfolio/technical.jpg",
      //   "url":"https://github.com/devthakker/ilib"
      // },
      {
        "name":"Icarus-BT",
        "description":"Python library for backtesting trading algorithms for US markets such as NYSE, NASDAQ, and OTC.",
        "imgurl":"images/portfolio/hft.jpg",
        "url":"https://github.com/devthakker/Icarus-bt"
      },
      {
        "name":"Red Bank",
        "description":"Website for financial and technical analysis or securities",
        "imgurl":"images/portfolio/technical.jpg",
        "url":"https://redbankcapital.co"
      }
    ],
    // "testimonials":[
    //   {
    //     "description":"This is a sample testimonial",
    //     "name":"Some technical guy"
    //   },
    // ]
  }
  
  export default resumeData